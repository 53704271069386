import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { InitSearchRequest } from '../../../../../../search/services/search/websocket-tours.model';

@Injectable({ providedIn: 'root' })
export class SearchToursProgressService {
  private searchInProgressSub = new BehaviorSubject<boolean>(false);
  private searchCompletedSub = new Subject<void>();
  private searchStaredSub = new BehaviorSubject<InitSearchRequest>(null);

  get searchInProgress$(): Observable<boolean> {
    return this.searchInProgressSub.asObservable();
  }

  get searchCompleted$(): Observable<void> {
    return this.searchCompletedSub.asObservable();
  }

  get searchStarted$(): Observable<InitSearchRequest> {
    return this.searchStaredSub.asObservable();
  }

  searchStarted(initSearchRequest: InitSearchRequest): void {
    this.searchStaredSub.next(initSearchRequest);
    this.searchInProgressSub.next(true);
  }

  searchCompleted(): void {
    this.searchInProgressSub.next(false);
    this.searchCompletedSub.next();
  }
}
