import { SearchResultGroup } from '../search-tours.model';

const layoutMap = {
  й: 'q',
  ц: 'w',
  у: 'e',
  к: 'r',
  е: 't',
  н: 'y',
  г: 'u',
  ш: 'i',
  щ: 'o',
  з: 'p',
  х: '[',
  ъ: ']',
  ф: 'a',
  ы: 's',
  в: 'd',
  а: 'f',
  п: 'g',
  р: 'h',
  о: 'j',
  л: 'k',
  д: 'l',
  ж: ';',
  э: "'",
  я: 'z',
  ч: 'x',
  с: 'c',
  м: 'v',
  и: 'b',
  т: 'n',
  ь: 'm',
  б: ',',
  ю: '.',
  ё: '`',
};

export function convertToRussianLayout(text: string) {
  const russianLayout = Object.fromEntries(Object.entries(layoutMap).map(([key, value]) => [value, key]));

  return text
    .split('')
    .map(char => russianLayout[char] || char)
    .join('');
}

export function convertToEnglishLayout(text: string) {
  return text
    .split('')
    .map(char => layoutMap[char] || char)
    .join('');
}

export function filterResultGroupsBySearchQueryFunctions(
  groups: SearchResultGroup[],
  searchText: string,
): SearchResultGroup[] {
  const searchTextEnglish = convertToEnglishLayout(searchText.toLowerCase());

  return groups.filter(group => {
    const hotelName = group.hotel.name.toLowerCase();
    return hotelName.includes(searchText.toLowerCase()) || hotelName.includes(searchTextEnglish);
  });
}
