import { inject, Injectable } from '@angular/core';
import { ChatContactsListItem, CrmCardViewItem } from '@api-clients/crm-api-client';
import { Router } from '@angular/router';
import { ChatsDataService } from '../../../+state/chats/store/chats.data.service';

@Injectable({
  providedIn: 'root',
})
export class ChatsNavigationService {
  private readonly chatsDataService = inject(ChatsDataService);

  /**
   * Например, со звонка. Там есть только карточка и номер телефона
   */
  navigateAndOpenChatFromCrmCardViewItem(
    crmCardViewItem: CrmCardViewItem,
    router: Router,
    // callingPhoneNumber?: PhoneItem, // с какого номера идет звонок!
  ) {
    // todo: переделать на запрос по contacts/listView - view одного контакта по id,
    //  со всеми expand и прочей херней
    const props = this.chatsDataService.openChatByCrmCardViewItem(crmCardViewItem);
    void router.navigate(['/chats', props.phoneOrContactId]);
  }

  // ex: findAndChooseOrLoadNewChatContact
  navigateAndOpenChatByContact(item: ChatContactsListItem, invisibleRead: boolean, router: Router) {
    const props = this.chatsDataService.openChatByContact(item, invisibleRead);
    void router.navigate(['/chats', props.phoneOrContactId]);
  }

  rxOpenChatByContactId(chatContactId: string, router: Router) {
    this.chatsDataService.resetSelectedItemState();
    void router.navigate(['/chats', chatContactId]);
  }

  // openChatByPhoneNumber(phoneNumber: string, router: Router) {
  //   console.log('todo openChatByPhoneNumber: comings soon');
  // },
}
